<template>
      <b-card header="Click either Maths or English blue button to book your teaching session day and time">
        <b-table-simple hover striped>
          <b-tbody>
            <template v-for="a, idx of appointmentsWithEmptySlots">
              <iris-appointment-slot v-if="a" :key="a._id" :name="`Session ${idx + 1}`"
              :appointment="a" @delete="cancelEvent(a)" @reschedule="rescheduleEvent" :show-connect-link="showConnectLinks" />
              <b-tr v-else :key="idx">
                <b-td>
                  Book Session {{ idx + 1 }}
                </b-td>
                <b-td colspan="2">
                  <b-button v-for="{ subject, icon, url } of calendlyUrls" :key="subject" @click="handleClick(url)" variant="primary" class="mt-1 mr-1" v-b-tooltip.hover :title="`Book ${subject} teaching session ${ idx + 1}`">
                    <b-icon v-if="icon" :icon="icon" /> {{ subject }}
                  </b-button>
                </b-td>
            </b-tr>              
            </template>
          </b-tbody>
        </b-table-simple>
      </b-card>
</template>

<script setup lang="ts">

import { Interval } from 'luxon'
import { useFetch } from '@/utils/auth'
import Calendly from 'node-calendly'
import { useCalendly } from '~~/calendly/composables/useCalendly'
import { useCalendlyEventListener, activeCalendlyComponent } from '~~/calendly/composables/useCalendlyEventListener'
import { SubscriberPost } from '~~/server/api/appointmnts/[subscriberId].post'
import { AppointmentSlotInterface } from '~~/server/models/appointmentSlot'
import { stringify } from 'qs'
import { getAuthHeaders } from '~~/utils/authorisation'

const instance = getCurrentInstance().proxy
const context = useNuxtApp()

export type AppointmentBlockProps = {
        /** subscriber id */
        subscriberId: number
        /** subscribers email */
        email: string
        /** first name */
        firstName: string
        /** last name  */
        lastName: string
        /** number of slots available */
        slotsAvailable: number
        /** time period allowed */
        interval?: Interval
        /** optional invoice id */
        invoiceId?: string
        /** plan string */
        planCode: string
        /** show iris connect links */
        showConnectLinks?: boolean
}
    const props = defineProps<AppointmentBlockProps>()

    const emits = defineEmits<{
      (event: 'slotsUnbooked', numberOfSlotsAvailable: number): void
    }>()

    const { data: appointments, execute: refreshAppointments } = useFetch(computed(() => `/api/appointmnts/${props.subscriberId}?${stringify({
      ...props.invoiceId ? {
        invoiceId: props.invoiceId
      }: {},
      ...props.interval ? {
        start: props.interval.start.toISO(),
        end: props.interval.end.toISO()
      } : {}
    })}`), {
        initialData: [],
  afterFetch(ctx) {
    // console.log(ctx.data)
    ctx.data = ctx.data.map(d => ({
      ...d,
      startTime: new Date(d.startTime)
    }))
    return ctx
  },
}).json<AppointmentSlotInterface[]>()

const unbookedSlotsAvailable = computed(() => Math.max(props.slotsAvailable - appointments.value.length, 0))

watch(unbookedSlotsAvailable, (newV, oldV) => {
  if (newV !== oldV) {
    emits('slotsUnbooked', newV)
  }
}, {
  immediate: true
})

const appointmentsWithEmptySlots = computed(() => {
  return [...appointments.value, ...new Array(unbookedSlotsAvailable.value)]
})


const cancelEvent = async (appt: AppointmentSlotInterface) => {
  return $fetch(`/api/appointmnts/${appt.subscriberId}/${appt.eventId}`, {
    method: 'DELETE',
    headers: await getAuthHeaders(context.$auth)
  }).then(() => refreshAppointments())
}

const calendlyUrls = computed<[
  {subject: string; url: string, icon?: string}
]>(() => {
  if (typeof config.public.calendlyUrl === 'string') {
    return [{
      url: config.public.calendlyUrl,
      subject: 'Tutor Session',
      icon: 'calendar'
    }]
  }
  return config.public.calendlyUrl
})

if ((process as any).client) {
  useCalendlyEventListener({
      onEventScheduled: async event => {
        if (activeCalendlyComponent.value !== (instance as any)._uid) {
          return
        }
        activeCalendlyComponent.value = undefined
        // console.log('onEventScheduled', event)
        // events.value.push(event.data)
        const id = Calendly.getUuidFromUri((event.data.payload as any).event.uri)
        const invitee = Calendly.getUuidFromUri((event.data.payload as any).invitee.uri)
        await calendly.closePopupWidget()
        const resp = await $fetch(`/api/appointmnts/${props.subscriberId}`, {
          method: 'POST',
          headers: await getAuthHeaders(context.$auth),
          body: {
            eventId: id,
            inviteeId: invitee,
            email: props.email,
            ...props.interval ? {
              minDate: props.interval.start.toISO(),
              maxDate: props.interval.end.toISO()
            } : {},
            ...props.invoiceId ? {
              invoiceId: props.invoiceId
            } : {}
          } as SubscriberPost
        }).then(() => refreshAppointments()).catch(e => {
            if (e.name === 'FetchError' && e.response.status === 400) {
                return instance.$bvModal.msgBoxOk(e.data.message, {
                  title: 'Appointment outside of period'
                })
            }
            throw e  
        })
        // const resp = await $fetch(`/api/event/${id}`)
        // console.log(resp)
        // events.value.push(resp)
        // const respInvitee = await $fetch(`/api/event/${id}/invitees/${invitee}`)
        // events.value.push(respInvitee)
        // console.log(id)
      },
      // onEventTypeViewed: event => {
      //   console.log('onEventTypeViewed', event)
      //   events.value.push(event.data)
      //   // console.log(Calendly.getUuidFromUri((event.data.payload as any).uri))
      // },
      // onProfilePageViewed: event => {
      //   console.log('onProfilePageViewed', event)
      //   events.value.push(event.data)
      //   // console.log(Calendly.getUuidFromUri((event.data.payload as any).uri))
      // },
    })
}


const calendly = useCalendly()

const config = useRuntimeConfig()

/** for reschedule need to know when calendly goes away */
let observer: MutationObserver

onUnmounted(() => {
  observer?.disconnect()
})

const rescheduleEvent = (url) => {
  observer = new MutationObserver((record) => {
    for (const mutation of record) {
      for (const el of mutation.removedNodes) {
        if (el instanceof HTMLElement) {
          if (el.className.includes('calendly')) {
            observer.disconnect()
            observer = null
            refreshAppointments()
          }
        }
      }
    }
  })
  // start watching
  observer.observe(document.body, {
      childList: true
  })
  return handleClick(url)
}

const handleClick = (url: string) => {
  activeCalendlyComponent.value = (instance as any)._uid
  return calendly.initPopupWidget({
    prefill: {
      email: props.email,
      firstName: props.firstName,
      lastName: props.lastName,
      name: `${props.firstName} ${props.lastName}`,
      date: props.interval ? props.interval.start.toJSDate() : undefined,
      customAnswers: {
        a1: `${props.subscriberId}`,
        a2: `${props.planCode} - ${appointments.value.length + 1}/${props.slotsAvailable}${props.invoiceId ? ` Extra Sessions` : ''}`
      }
    },
    pageSettings: {
      hideEventTypeDetails: true,
      hideGdprBanner: true,
      hideLandingPageDetails: true,
    },
    url: `${url}?hide_gdpr_banner=1`
  })
}



</script>
